<template>
  <form @submit.prevent="updateTaskType">
    <modal-card :title="`Update task type`" :processing="processing">
      <loading v-if="loading" />
      <b-field v-else label="Task *">
        <task-select
          :software="software"
          :disabled="processing"
          :task="form.task"
          :has-credits="!!task.creditCost"
          data-inflex="stretch size:lg"
          required
          expanded
          @selected="form.task = $event"
        />
      </b-field>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm || processing"
        type="submit"
        class="button is-success"
      >
        Submit
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "UpdateTaskTypeModal",
  components: {
    "task-select": () => import("./_taskSelect")
  },
  props: {
    taskId: {
      required: true,
      type: String
    },
    taskTypeRef: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      form: {
        task: {},
        details: ""
      }
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.taskId) || {};
    },
    site() {
      return this.$store.getters["sites/site"](this.task.siteId) || {};
    },
    software() {
      return this.$_.get(this, "site.software", "none");
    },
    validForm() {
      if (this.$_.get(this.form, "task.ref.path", "") === this.taskTypeRef.path)
        return false;
      if (this.$_.isEmpty(this.form.task)) return false;
      return true;
    }
  },
  created() {
    this.getTaskType();
  },
  methods: {
    getTaskType() {
      this.$store
        .dispatch("tasks/getTaskType", { taskTypeRef: this.taskTypeRef })
        .then(taskType => {
          this.$set(
            this.form,
            "task",
            this.$_.merge({}, { ref: taskType.ref }, taskType.data())
          );
          this.loading = false;
        })
        .catch(error => {
          this.$emit("close");
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    },
    updateTaskType() {
      this.processing = true;
      this.$store
        .dispatch("tasks/updateType", {
          taskId: this.taskId,
          taskTypePath: this.form.task.ref.path
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
